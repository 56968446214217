/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import {
  ChevronLeft,
  ChevronRight,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import axios from 'axios';
import LpFooter from 'components/lp-footer';
import SignupForm from 'components/signup-form';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';
import AliInCellphone from '../../images/AliInCellphone.png';
import AliLogo from '../../images/Logo-ali-2023.svg';
import AirfryerAlexa from '../../images/air-fryer-alexa.png';
import Airfryer from '../../images/airfryer.svg';
import AlivieOBolso from '../../images/alivie-o-bolso.svg';
import BubblesOfPeople from '../../images/bubbles-of-people.svg';
import Fliperama from '../../images/fliperama.png';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import GrillAlexa from '../../images/grill-alexa.png';
import GrillOster from '../../images/grill-oster.svg';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import ManPlaying from '../../images/man_playing.png';
import SmartTv from '../../images/smart-tv.png';
import HappyWoman from '../../images/woman_happy.svg';
import {
  Answer,
  ArrowButton,
  ArrowIcon,
  ArrowsContainer,
  BackgroundCircle,
  BlackDivider,
  Button,
  CampaingProgress,
  Container,
  Content,
  FaqBox,
  FaqContainer,
  FaqItem,
  Footer,
  Header,
  ImageContainer,
  LeftContent,
  LineLock,
  Prize,
  PrizeList,
  PrizeListContent,
  PrizesBox,
  Progress, ProgressItem, ProgressList, Question, Regulation, RegulationBox, RightContent,
} from './styles';

const stages = [150, 300, 450, 600, 750, 900, 1000];

const calculateStage = (registrationCount) => {
  let currentStage = 0;

  const nextStage = stages.find((stage) => registrationCount < stage) || stages[stages.length - 1];

  const currentStageIndex = stages.indexOf(nextStage);
  if (currentStageIndex > 0) {
    currentStage = stages[currentStageIndex - 1];
  }

  const remainingRegistrations = nextStage - registrationCount;

  return {
    currentStage,
    nextStage,
    remainingRegistrations,
  };
};

const LandingPageMgm = () => {
  const { companyMgm } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [registrationCount, setRegistrationCount] = React.useState(0);
  const [institutionalIsValid, setInstitutionalIsValid] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages/path/${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setPathData(response.data);
          setInstitutionalIsValid(true);
        }
      } catch (error) {
        setInstitutionalIsValid(false);
      }
    };

    checkEndpoint();
  }, [companyMgm]);

  React.useEffect(() => {
    const checkRegistrationCount = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages-subscriptions/count?landingPagePath=${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setRegistrationCount(response.data.validSubscriptions);
        }
      } catch (error) {
        setRegistrationCount(0);
      }
    };

    checkRegistrationCount();
  }, [companyMgm]);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScrollLeft = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft -= container.offsetWidth;
    setScrollPosition(container.scrollLeft - container.offsetWidth);
  };

  const handleScrollRight = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft += container.offsetWidth;
    setScrollPosition(container.scrollLeft + container.offsetWidth);
  };

  React.useEffect(() => {
    if (institutionalIsValid === false) {
      history.push('/');
    }
  }, [institutionalIsValid, history]);

  if (institutionalIsValid === null) {
    return false;
  }

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Até quando poderei participar e concorrer aos prêmios?',
      answer: 'Para participar e concorrer, você deverá fazer seu cadastro preenchendo o formulário na página <a href="https://www.ali.com.vc/alivie-o-bolso/frigol">www.ali.com.vc/alivie-o-bolso/frigol</a> até o dia 20/10.',
    },
    {
      question: 'Meu benefício Ali já está ativado. Posso participar?',
      answer: 'Sim. Basta você se inscrever no formulário da página <a href="https://www.ali.com.vc/alivie-o-bolso/frigol">www.ali.com.vc/alivie-o-bolso/frigol</a> e você concorrerá a um dos prêmios desbloqueados.',
    },
    {
      question: 'Como os prêmios são desbloqueados?',
      answer: 'Os prêmios seguirão uma dinâmica de destrave por quantidade mínima de cadastros, ao atingir o número de 150 novos cadastros, será realizado um sorteio para a liberação do prêmio disponibilizado para aquela faixa. Ou seja, a cada 150 colaboradores cadastrados, um novo sorteio será realizado.',
    },
    {
      question: 'Como saberei quem ganhou os prêmios sorteados?',
      answer: 'Os ganhadores de cada sorteio serão divulgados nos canais de comunicação oficiais da sua empresa a cada sorteio.',
    },
    {
      question: 'Quais prêmios e quantos sorteios acontecerão?',
      answer: 'Os prêmios são: Fone Jbl, Alexa Echo Pop, Air Flyer, Grill Oster, Tv e Fliperama. Seguindo esta ordem: <br /><br /> Sorteio 01: um fone JBL <br />Sorteio 02: uma Alexa Echo Pop. <br />Sorteio 03: uma Air Fryer <br />Sorteio 04: um Grill Oster <br />Sorteio 05: um grill Oster e uma Alexa Echo Pop. <br />Sorteio 06: uma air fryer e uma Alexa Echo Pop. <br />Sorteio 07: uma TV 50" para um colaborador, um fliperama para uma das unidades e uma Alexa para um dos RHs.',
    },
    {
      question: 'Meu nome foi sorteado. Quando receberei meus prêmios?',
      answer: 'Os prêmios serão postados em até 20 dias úteis após o último sorteio e a confirmação de endereço completo por parte dos vencedores.',
    },
  ];

  const stageInfo = calculateStage(registrationCount);

  if (institutionalIsValid) {
    return (
      <Container>
        <Helmet>
          <title>
            Ali |
            {' '}
            {pathData.friendlyName ?? ''}
          </title>
        </Helmet>
        <Header>
          <img src={AliLogo} alt="Ali logo" style={{ height: '70px' }} />
        </Header>
        <Content>
          <LeftContent>
            <ImageContainer>
              <img src={AlivieOBolso} alt="Alivie o Bolso" />
            </ImageContainer>
            <h1>Benefício que te ajuda a economizar e ganhar prêmios? A Frigol tem!</h1>
            <p>Inscreva-se para ativar seu benefício Ali e concorrer a prêmios incríveis.</p>
            <p>
              <b>Não se preocupe:</b>
              {' '}
              você só utiliza o benefício se quiser, combinado?
            </p>
          </LeftContent>
          <RightContent>
            <SignupForm company={pathData.id} origin="mgm" />
            <img src={HappyWoman} alt="Mulher contente, comemorando uma conquista enquanto segura um celular" style={{ height: '600px' }} />
          </RightContent>
        </Content>
        <BlackDivider>
          <div>
            <img src={BubblesOfPeople} alt="Pessoas" style={{ height: '70px' }} />
          </div>
          <div>
            <h1>Participe e chame seus amigos</h1>
            <h2>para desbloquear mais prêmios</h2>
          </div>
        </BlackDivider>
        <CampaingProgress>
          <h1>Ajude seus amigos a economizar:</h1>
          <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
          <Progress>
            <h1>
              Faltam apenas
              {' '}
              {stageInfo.remainingRegistrations}
              {' '}
              inscritos
            </h1>
            <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
            <ProgressList id="carousel">
              <ProgressItem>
                <h1>Sorteio 01:</h1>
                <h2>01 FONE BLUETOOTH JBL</h2>
                <br />
                <BackgroundCircle
                  active={stageInfo.currentStage === 0}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={HeadphoneJbl}
                  alt="Fone JBL"
                  style={stageInfo.currentStage === 0
                    ? { height: '180px', width: '250px' }
                    : { height: '130px', width: '200px' }}
                />
              </ProgressItem>
              <LineLock>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 150
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 02:</h1>
                <h2>01 ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 150}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={AlexaEchoPop}
                  alt="Alexa Echo Pop"
                  style={stageInfo.currentStage === 150
                    ? { height: '180px', width: '250px' }
                    : { height: '130px', width: '200px' }}
                />
              </ProgressItem>
              <LineLock>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 300
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 3:</h1>
                <h2>01 AIR FRYER MONDIAL</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 300}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={Airfryer}
                  alt="Airfryer"
                  style={stageInfo.currentStage === 300
                    ? { height: '180px', width: '250px' }
                    : { height: '130px', width: '200px' }}
                />
              </ProgressItem>
              <LineLock>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 450
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 04:</h1>
                <h2>01 GRILL OSTER</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 450}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={GrillOster}
                  alt="Grill"
                  style={stageInfo.currentStage === 450
                    ? { height: '180px', width: '250px' }
                    : { height: '130px', width: '200px' }}
                />
              </ProgressItem>
              <LineLock>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 600
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 05:</h1>
                <h2>01 GRILL OSTER + ALEXA ECHO POP</h2>
                <BackgroundCircle active={stageInfo.currentStage === 600}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={GrillAlexa}
                  alt="Grill e Alexa echo pop"
                  style={stageInfo.currentStage === 600
                    ? { height: '180px', width: '250px' }
                    : { height: '130px', width: '180px' }}
                />
              </ProgressItem>
              <LineLock large>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 750
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 06:</h1>
                <h2>01 AIRFRYER + ALEXA ECHO POP</h2>
                <BackgroundCircle active={stageInfo.currentStage === 750}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={AirfryerAlexa}
                  alt="Airfryer e Alexa echo pop"
                  style={stageInfo.currentStage === 750
                    ? { height: '160px', width: '220px' }
                    : { height: '130px', width: '180px' }}
                />
              </ProgressItem>
              <LineLock large>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 900
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 07:</h1>
                <h2 style={{ marginBottom: '0', width: '190px' }}>TV 50&ldquo; (COLABORADOR)</h2>
                <h2 style={{ marginTop: '0' }}>FLIPERAMA (UNIDADE)</h2>
                <BackgroundCircle active={stageInfo.currentStage === 900}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <img
                  src={Fliperama}
                  alt="Fliperama"
                  style={stageInfo.currentStage === 900
                    ? { height: '180px', width: '250px' }
                    : { height: '130px', width: '180px' }}
                />
              </ProgressItem>
            </ProgressList>
            <ArrowsContainer>
              <ArrowButton aria-label="scroll left" onClick={handleScrollLeft} disabled={scrollPosition <= 0}>
                <ChevronLeft />
              </ArrowButton>
              <ArrowButton aria-label="scroll right" onClick={handleScrollRight}>
                <ChevronRight />
              </ArrowButton>
            </ArrowsContainer>
          </Progress>
        </CampaingProgress>
        <PrizesBox>
          <h1>Você pode ganhar um desses prêmios incríveis</h1>
          <PrizeList>
            <PrizeListContent>
              <Prize>
                <h1>Fone Bluetooth JBL</h1>
                <img src={HeadphoneJbl} alt="Fone JBL" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Alexa Echo Pop</h1>
                <img src={AlexaEchoPop} alt="Alexa Echo Pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>AirFryer Mondial</h1>
                <img src={Airfryer} alt="Airfryer" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Grill Oster</h1>
                <img src={GrillOster} alt="Grill" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Grill Oster + Alexa Echo Pop</h1>
                <img src={GrillAlexa} alt="Grill e Alexa echo pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Air Fryer + Alexa Echo Pop</h1>
                <img src={AirfryerAlexa} alt="Airfryer e Alexa echo pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Smart TV 50&ldquo;</h1>
                <img src={SmartTv} alt="Smart TV 50 polegadas" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Fliperama</h1>
                <img src={Fliperama} alt="Fliperama" style={{ height: '150px', width: '250px' }} />
              </Prize>
            </PrizeListContent>
          </PrizeList>
        </PrizesBox>
        <FaqBox>
          <div>
            <img src={ManPlaying} alt="Homem jogando" />
          </div>
          <div>
            <h2>Regras da Campanha</h2>
            <FaqContainer>
              {faqData.map((item, index) => (
                <FaqItem key={item.answer}>
                  <Question onClick={() => toggleFaq(index)}>
                    {item.question}
                    <ArrowIcon isOpen={activeIndex === index}><ExpandMoreOutlined /></ArrowIcon>
                  </Question>
                  <Answer
                    isOpen={activeIndex === index}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </FaqItem>
              ))}
            </FaqContainer>
          </div>
        </FaqBox>
        <RegulationBox>
          <Regulation>
            <div>
              <h2>Acesse o regulamento completo,</h2>
              <h2>&ldquo;#FrigolComAli&ldquo;.</h2>
              <Button href="https://qrco.de/regra-de-campanha" target="_blank">
                Consulte
              </Button>
            </div>
            <img src={AliInCellphone} alt="Mulher segurando o celular e abrindo o app da AliCrédito" />
          </Regulation>
        </RegulationBox>
        <Footer>
          <LpFooter />
        </Footer>
      </Container>
    );
  }

  return null;
};

export default LandingPageMgm;
